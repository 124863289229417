<template>
  <div>
    <v-list
      v-if="show === true"
      class="py-2"
      three-line
    >
      <template v-for="(item, index) in listData">
        <!--ID, Name, Status-->
        <v-list-item
          :key="index"
          class="my-1"
        >
          <v-list-item-content>
            <!----------------------------------------Line 1---------------------------------------->
            <v-list-item-title
              class="text-h6 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'secondary--text' : 'white--text'"
            >
              <!--Not FAQ AND Not Project Selector-->
              <span
                v-if="section !== 7 && section !== 1.2 && section !== 29"
              >
                {{ item.id }}:
              </span>

              <!--Projects-->
              <span
                v-if="section === 1"
              >
                {{ item.code }} - {{ item.short_name }}
              </span>

              <!--Projects Selector-->
              <span
                v-if="section === 1.2"
              >
                {{ item.code }} - {{ item.short_name }}
              </span>

              <!--Cohorts, Jurisdictions, Institutions-->
              <span
                v-if="section === 2 || section === 3 || section === 4 || section === 4.1 || section === 22.3 || section === 22.4"
              >
                {{ item.name }}
              </span>

              <!--Users-->
              <span
                v-if="section === 5 || section === 5.3"
              >
                {{ item.name_reverse }}
              </span>

              <!--Help Topics-->
              <span
                v-if="section === 6"
              >
                {{ item.title }}
              </span>

              <!--FAQ-->
              <span
                v-if="section === 7"
              >
                {{ item.title }}
              </span>

              <!--Funding-->
              <span
                v-if="section === 10.1 || section === 10.2"
              >
                {{ item.title }}
              </span>

              <!--Publications-->
              <span
                v-if="section === 11.1 || section === 11.2"
              >
                {{ item.title }}
              </span>

              <!--Patents-->
              <span
                v-if="section === 12.1 || section === 12.2"
              >
                {{ item.title }}
              </span>

              <!--Presentations-->
              <span
                v-if="section === 13.1 || section === 13.2"
              >
                {{ item.title }}
              </span>

              <!--Fellowships-->
              <span
                v-if="section === 14.1 || section === 14.2"
              >
                {{ item.title }}
              </span>

              <!--Dissertations-->
              <span
                v-if="section === 23.1 || section === 23.2"
              >
                {{ item.title }}
              </span>

              <!--Outcomes-->
              <text-display
                v-if="section === 15.2"
                :display-string="item.owner.name_reverse"
                :label="'Author of Record'"
                side-label
              />

              <!--Emails-->
              <span
                v-if="section === 24"
              >
                {{ $_formatDate(item.date_send) }} - {{ item.time_send }}
              </span>

              <!--Departments-->
              <span
                v-if="section === 25"
              >
                {{ item.name }}
              </span>

              <!--Participation From Settings User Detail-->
              <span
                v-if="section === 26"
              >
                {{ item.project.code }} - {{ item.project.short_name }}
              </span>

              <!--Participation From Settings Project User Detail-->
              <span
                v-if="section === 26.12"
              >
                {{ item.user.name }}
              </span>

              <!--Participation Start Dates-->
              <span
                v-if="section === 26.13 || section === 26.14"
              >
                Start: {{ item.date_start_month }} {{ item.date_start_year }}
              </span>
              <!--Participation End Dates-->
              <span
                v-if="section === 26.13 && item.date_end_month || section === 26.14 && item.date_end_month"
              >
                End: {{ item.date_end_month }} {{ item.date_end_year }}
              </span>

              <!--Years-->
              <span
                v-if="section === 29"
              >
                {{ item.project.code }} Year {{item.number}}
              </span>
            </v-list-item-title>




            <!----------------------------------------Line 2--------------------------------------->
            <!--Not Cohorts AND Not Jurisdictions-->
            <v-list-item-title
              v-if="section === 1 || section === 1.2 || section === 4 || section === 4.1 || section === 5 || section === 5.3|| section === 6 || section === 7 || section === 10.2 || section === 11.2 || section === 12.2 || section === 13.2 || section === 14.2 || section === 15.2 || section === 23.2 || section === 24 || section === 26 || section === 26.12 || section === 26.13 || section === 26.14 || section === 29"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Projects-->
              <span
                v-if="section === 1"
              >
                {{ item.cohort.name }}
              </span>

              <!--Project Selector-->
              <span
                v-if="section === 1.2"
              >
                Start Date: {{ $_formatDate(item.date_start) }}
              </span>

              <!--Institutions-->
              <span
                v-if="section === 4 || section === 4.1"
              >
                {{ item.jurisdiction.name }}
              </span>

              <!--Users-->
              <span
                v-if="section === 5"
              >
                <span v-if="item.flag_admin === 1">Site Admin</span>
              </span>

              <!--Users-->
              <span
                v-if="section === 5 || section === 5.3"
              >
                {{ item.email }}
              </span>

              <!--Help Topics-->
              <span
                v-if="section === 6"
              >
                <span>{{ $_getUserType(item.flag_type) }}</span>
              </span>

              <!--FAQ-->
              <span
                v-if="section === 7"
              >
                {{ $_getFaqType(item.flag_type) }}
              </span>

              <!--Funding-->
              <text-display
                v-if="section === 10.2"
                :display-string="item.owner.name_reverse"
                :label="'PI Of Record'"
                side-label
              />

              <!--Publications-->
              <text-display
                v-if="section === 11.2"
                :display-string="item.owner.name_reverse"
                :label="'Author of Record'"
                side-label
              />

              <!--Patents-->
              <text-display
                v-if="section === 12.2"
                :display-string="item.owner.name_reverse"
                :label="'Applicant of Record'"
                side-label
              />

              <!--Presentations-->
              <text-display
                v-if="section === 13.2"
                :display-string="item.owner.name_reverse"
                :label="'Author of Record'"
                side-label
              />

              <!--Fellowships-->
              <text-display
                v-if="section === 14.2"
                :display-string="item.organization"
                :label="'Funding Organization'"
                side-label
              />

              <!--Dissertations-->
              <text-display
                v-if="section === 23.2"
                :display-string="item.authors"
                :label="'Authors'"
                side-label
              />

              <!--Outcomes-->
              <text-display
                v-if="section === 15.1 || section === 15.2"
                :display-string="item.type"
                :label="'Type'"
                side-label
              />

              <!--Emails-->
              <span
                v-if="section === 24"
              >
                {{ item.subject }}
              </span>

              <!--Participation Start Dates-->
              <span
                v-if="section === 26 || section === 26.12"
              >
                Start: {{ item.date_start_month }} {{ item.date_start_year }}
              </span>
              <!--Participation End Dates-->
              <span
                v-if="section === 26 && item.date_end_month ||section === 26.12 && item.date_end_month"
              >
                End: {{ item.date_end_month }} {{ item.date_end_year }}
              </span>

              <!--Particiption-->
              <span
                v-if="section === 26.13 || section === 26.14"
              >
                {{ $_getUserType(item.flag_user_type) }}
              </span>
              <span
                v-if="section === 26.13 && item.flag_user_type === 3 && item.classification || section === 26.14 && item.flag_user_type === 3 && item.classification || section === 26.14 && item.flag_user_type === 2 && usersStore.authUserType !== 2 && item.classification || section === 26.13 && item.flag_user_type === 2 && usersStore.authUserType!== 2 && item.classification"
              >
                {{ ' - ' + item.classification }}
              </span>
              <span
                v-if="section === 26.14 && item.flag_user_type === 2 && item.title || section === 26.13 && item.flag_user_type === 2 && item.title"
              >
                {{ ' (' + item.title + ')' }}
              </span>

              <!--Years-->
              <span
                v-if="section === 29"
              >
                {{ $_formatDate(item.date_start) }} - {{ $_formatDate(item.date_end) }}
              </span>
            </v-list-item-title>


            <!------------------------------------------Line 3---------------------------------------->
            <!--Not Cohorts AND Not Jurisdictions AND Not Institutions-->
            <v-list-item-title
              v-if="section === 1 || section === 1.2 || section === 5 || section === 6 || section === 7 || section === 10.2 || section === 11.2 || section === 12.2 || section === 13.2 || section === 14.2 || section === 15.2 || section === 23.2 || section === 26 || section === 26.12 || section === 26.12 || section === 26.13 || section === 26.14"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Projects-->
              <span
                v-if="section === 1 && item.jurisdictions && item.jurisdictions.length > 0"
              >
                <span
                  v-for="(jurisdiction,index) in item.jurisdictions"
                  :key="item.id + '-' + jurisdiction.id + index + 'A'"
                >{{ jurisdiction.name }}<span v-if="(index + 1) < item.jurisdictions.length">-</span></span>
              </span>

              <!--Contact PI-->
              <span
                v-if="section === 1.2 && item.contactpi && item.contactpi.length > 0"
              >
                Principal Investigator: <span
                  v-for="(pi,index) in item.contactpi"
                  :key="index"
                >{{ pi.name_reverse }}<span v-if="(index + 1) < item.contactpi.length">-</span></span>
              </span>

              <!--Users-->
              <span
                v-if="section === 5 && item.projects"
              >
                <span
                  v-for="(project,index) in item.projects"
                  :key="item.id + '-' + project.id + index + 'B'"
                >
                  <span
                    v-if="project.pivot.flag_status !== 1"
                    class="red--text"
                  >{{ project.code }}<span v-if="(index + 1) < item.projects.length">, </span></span>
                  <span
                    v-else-if="project.pivot.flag_contact_pi === 1"
                    id="bubble-label-success"
                  >{{ project.code }}</span>
                  <span
                    v-else
                    id="bubble-label-normal"
                  >{{ project.code }}</span>
                </span>
              </span>

              <!--Help Topics-->
              <span
                v-if="section === 6"
              >
                <!-- <span
                  v-if="item.text.length > 200"
                >
                  {{ item.text.substr(0, 200) }}...
                </span>
                <span
                  v-else
                > -->
                {{ item.text }}
                <!-- </span> -->

              </span>

              <!--FAQ-->
              <span
                v-if="section === 7"
              >
                {{ item.text }}
              </span>

              <!--Particiaption-->
              <span
                v-if="section === 26 || section === 26.12"
              >
                {{ $_getUserType(item.flag_user_type) }}
              </span>
              <span
                v-if="section === 26 && item.flag_user_type === 2 && item.classification || section === 26 && item.flag_user_type === 3 && item.classification ||section === 26.12 && item.flag_user_type === 2 && item.classification || section === 26.12 && item.flag_user_type === 3 && item.classification"
              >
                {{ ' - ' + item.classification }}
              </span>
              <span
                v-if="section === 26 && item.flag_user_type === 2 && item.title || section === 26.12 && item.flag_user_type === 2 && item.title"
              >
                {{ '(' + item.title + ')' }}
              </span>

              <!--Funding-->
              <text-display
                v-if="section === 10.2"
                :display-string="item.organization"
                :label="'Funding Organization'"
                side-label
              />

              <!--Publications-->
              <text-display
                v-if="section === 11.2"
                :display-string="item.type"
                :label="'Publication Type'"
                side-label
              />
              
              <!--Patents-->
              <text-display
                v-if="section === 12.2"
                :display-string="item.patent_number"
                :label="'Patent Number'"
                side-label
              />

              <!--Presentations-->
              <text-display
                v-if="section === 13.2"
                :display-string="item.type"
                :label="'Type'"
                side-label
              />

              <!--Outcomes-->
              <text-display
                v-if="section === 15.2 && item.type !== 'Other Product' && item.type !== 'Website'"
                :display-string="item.title"
                :label="'Title'"
                side-label
              />

              <text-display
                v-if="section === 15.2 && item.type === 'Other Product'"
                :display-string="item.product_type"
                :label="'Type'"
                side-label
              />

              <text-display
                v-if="section === 15.2 && item.type === 'Website'"
                :display-string="item.url"
                :label="'URL'"
                side-label
              />

              <!--Fellowships-->
              <text-display
                v-if="section === 14.2"
                :display-string="item.type"
                :label="'Award Type'"
                side-label
              />

              <!--Dissertations-->
              <text-display
                v-if="section === 23.2"
                :display-string="item.institution"
                :label="'Institution'"
                side-label
              />
            </v-list-item-title>



            <!----------------------------------------Line 4--------------------------------------->
            <v-list-item-title
              v-if="section === 10.2 || section === 11.2 || section === 12.2 || section === 13.2 || section === 14.2 || section === 15.2 || section === 23.2"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Funding-->
              <text-display
                v-if="section === 10.2"
                :display-string="item.date_month + ' ' + item.date_year"
                :label="'Submitted Date'"
                side-label
              />

              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Journal Article Print' || item.type === 'Journal Article Electronic Only' || item.type === 'Conference Proceeding (Juried)')"
                :display-string="item.journal"
                :label="(item.type === 'Journal Article Print' || item.type === 'Journal Article Electronic Only') ? 'Journal (Full Title)' : 'Journal, Book or Website (URL)'"
                side-label
              />

              <!--Patents-->
              <text-display
                v-if="section === 12.2"
                :display-string="`${item.date_month} ${item.date_year}`"
                :label="'Submitted Date'"
                side-label
              />

              <!--Presentations-->
              <text-display
                v-if="section === 13.2"
                :display-string="item.event"
                :label="'Event Name'"
                side-label
              />

              <!--Outcomes-->
              <text-display
                v-if="section === 15.2 && item.description"
                :display-string="item.description"
                :label="'Description'"
                side-label
              />

              <!--Fellowships-->
              <text-display
                v-if="section === 14.2"
                :display-string="`${item.date_month} ${item.date_year}`"
                :label="'Submitted Date'"
                side-label
              />

              <!--Dissertations-->
              <text-display
                v-if="section === 23.2"
                :display-number="item.year"
                :label="'Year'"
                side-label
              />
            </v-list-item-title>



            <!----------------------------------------Line 5--------------------------------------->
            <v-list-item-title
              v-if="section === 10.2 || section === 11.2 || section === 12.2 || section === 13.2 || section === 14.2 || section === 23.2"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Funding-->
              <text-display
                v-if="section === 10.2"
                :display-string="(item.amount_requested ? 'Requested - ' + $_formatMoney(item.amount_requested) : '') + (item.amount_awarded ? ' and Awarded - ' + $_formatMoney(item.amount_awarded) : '')"
                :label="'Total Proposal Amount'"
                side-label
              />

              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Book Chapter')"
                :display-string="item.book_title"
                :label="'Book Title'"
                side-label
              />

              <text-display
                v-if="section === 11.2 && (item.type === 'Conference Proceeding')"
                :display-string="item.journal"
                :label="'Journal, Book or Website (URL)'"
                side-label
              />

              <!--Patents-->
              <text-display
                v-if="section === 12.2 && (item.flag_status === 2)"
                :display-string="`${item.date_awarded_month} ${item.date_awarded_year}`"
                :label="'Awarded Date'"
                side-label
              />

              <!--Presentations-->
              <text-display
                v-if="section === 13.2"
                :display-string="item.location"
                :label="'Location'"
                side-label
              />

              <!--Fellowships-->
              <text-display
                v-if="section === 14.2"
                :display-string="item.flag_status === 0 ? 'Not Awarded' : item.flag_status === 1 ? 'Submitted and Pending' : 'Awarded'"
                :label="'Status'"
                side-label
              />

              <!--Dissertations-->
              <text-display
                v-if="section === 23.2"
                :display-string="item.type"
                :label="'Type'"
                side-label
              />
            </v-list-item-title>



            <!----------------------------------------Line 6--------------------------------------->
            <!--Funding List-->
            <v-list-item-title
              v-if="section === 10.2 || section === 11.2 || section === 14.2 || section === 13.2"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Funding-->
              <text-display
                v-if="section === 10.2 && (item.flag_status === 2)"
                :display-string="`${item.date_awarded_month ? 'Start - ' + item.date_awarded_month + ' ' + item.date_awarded_year : ''} ${item.date_awarded_month_end ? 'and End - ' + item.date_awarded_month_end + ' ' + item.date_awarded_year_end : ''}`"
                :label="'Funding Dates'"
                side-label
              />

              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Conference Proceeding (Juried)')"
                :display-string="item.conferenceName"
                :label="'Conference Name'"
                side-label
              />

              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Conference Proceeding')"
                :display-string="item.month + ' ' + item.year"
                :label="'Publication Date'"
                side-label
              />

              <!--Fellowships-->
              <text-display
                v-if="section === 14.2"
                :display-string="`${ item.amount_requested ? 'Requested - ' + $_formatMoney(item.amount_requested) : '' } ${ item.amount_awarded ? 'and Awarded - ' + $_formatMoney(item.amount_awarded) : '' }`"
                :label="'Total Fellowship Amount'"
                side-label
              />

              <!--Presentations-->
              <text-display
                v-if="section === 13.2 && (item.flag_status === 1)"
                :display-string="`${item.date_month} ${item.date_year}`"
                :label="'Published Date'"
                side-label
              />

              <!--Presentations-->
              <text-display
                v-if="section === 13.2 && (item.flag_status !== 1)"
                :display-string="`${item.date_estimated_published_month} ${item.date_estimated_published_year}`"
                :label="'Estimated Date'"
                side-label
              />
            </v-list-item-title>



            <!----------------------------------------Line 6--------------------------------------->
            <!--Funding List-->
            <v-list-item-title
              v-if="section === 11.2 || section === 14.2"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Journal Article Print' || item.type === 'Conference Proceeding (Juried)' || item.type === 'Book Chapter' || item.type == 'Book')"
                :display-string="(item.type === 'Book Chapter' || item.type == 'Book') ? item.year : `${item.month} ${item.year}`"
                :label="'Publication Date'"
                side-label
              />

              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Conference Proceeding')"
                :display-string="item.peer_review"
                :label="'Peer Reviewed'"
                side-label
              />

              <!--Fellowships-->
              <text-display
                v-if="section === 14.2"
                :display-string="`${ item.date_awarded_month ? `Start - ${item.date_awarded_month} ${item.date_awarded_year}` : '' } ${ item.date_awarded_month_end ? `and End - ${item.date_awarded_month_end} ${item.date_awarded_year_end}` : '' }`"
                :label="'Fellowship Dates'"
                side-label
              />
            </v-list-item-title>



            <!----------------------------------------Line 6--------------------------------------->
            <!--Funding List-->
            <v-list-item-title
              v-if="section === 11.2"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Journal Article Electronic Only')"
                :display-string="item.url"
                :label="'URL'"
                side-label
              />

              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Conference Proceeding')"
                :display-string="item.support_acknowledgment"
                :label="'EPSCoR T-2 Support Acknowledgement'"
                side-label
              />
            </v-list-item-title>



            <!----------------------------------------Line 6--------------------------------------->
            <!--Funding List-->
            <v-list-item-title
              v-if="section === 11.2"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Journal Article Print' || item.type === 'Conference Proceeding (Juried)' || item.type === 'Book Chapter' || item.type === 'Journal Article Electronic Only')"
                :display-string="item.peer_review"
                :label="'Peer Reviewed'"
                side-label
              />
            </v-list-item-title>



            <!----------------------------------------Line 6--------------------------------------->
            <!--Funding List-->
            <v-list-item-title
              v-if="section === 11.2"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Journal Article Print' || item.type === 'Conference Proceeding (Juried)' || item.type === 'Book Chapter' || item.type === 'Book' || item.type === 'Journal Article Electronic Only')"
                :display-string="item.support_acknowledgment"
                :label="'EPSCoR T-2 Support Acknowledgement'"
                side-label
              />
            </v-list-item-title>



            <!----------------------------------------Line 6--------------------------------------->
            <!--Funding List-->
            <v-list-item-title
              v-if="section === 11.2"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Publications-->
              <text-display
                v-if="section === 11.2 && (item.type === 'Journal Article Electronic Only')"
                :display-string="`${item.citedMonth} ${item.citedYear}`"
                :label="'Publication Date'"
                side-label
              />
            </v-list-item-title>




            <!------------------------------------------Line---------------------------------------->
            <v-list-item-title
              v-if="section === 1.2"
              class="text-body-1 font-weight-medium"
              :class="$vuetify.theme.dark === false ? 'nsfgrey--text' : 'white--text'"
            >
              <!--Projects-->
              <span
                v-if="section === 1.2 && item.jurisdictions && item.jurisdictions.length > 0"
              >
                Jurisdictions: <span
                  v-for="(jurisdiction,index) in item.jurisdictions"
                  :key="index + '-' + jurisdiction.id + index + 'C'"
                >{{ jurisdiction.name }}<span v-if="(index + 1) < item.jurisdictions.length">-</span></span>
              </span>
            </v-list-item-title>



            <!------------------------------------------Timestamps---------------------------------------->
            <v-list-item-title 
              class="text-caption grey--text"
              :class="$vuetify.theme.dark === false ? 'text--darken-1' : ''"
            >
              <!--Created and Not Project Selector-->
              <span
                v-if="item.created_at && section !== 1.2"
              >
                Created: {{ $_formatTimestamp(item.created_at) }}
              </span>

              <!---->
              <span
                v-if="item.created_at && item.updated_at"
              >
                <span v-if="section !== 1.2">&#8226;</span>
              </span>
              
              <!--Updated and Not Project Selector-->
              <span
                v-if="item.updated_at && section !== 1.2"
              >
                Modified: {{ $_formatTimestamp(item.updated_at) }}
              </span>
            </v-list-item-title>
          </v-list-item-content>




          <!------------------------------------------List Actions---------------------------------------->
          <v-list-item-action>
            <!--Action Text-->
            <v-list-item-action-text>
              <!--Funding-->
              <span
                v-if="section === 10.1 || section === 10.2 || section === 14.1 || section === 14.2"
                class="text-body-1 font-weight-bold"
                :class="$_fundingStatusColor(item.flag_status)"
              >
                {{ $_fundingStatus(item.flag_status) }}
              </span>

              <!--Publication-->
              <span
                v-if="section === 11.1 || section === 11.2"
                class="text-body-1 font-weight-bold"
                :class="$_publicationStatusColor(item.flag_status)"
              >
                {{ $_publicationStatus(item.flag_status) }}
              </span>

              <!--Patent-->
              <span
                v-if="section === 12.1 || section === 12.2"
                class="text-body-1 font-weight-bold"
                :class="$_patentStatusColor(item.flag_status)"
              >
                {{ $_patentStatus(item.flag_status) }}
              </span>

              <!--Presentation-->
              <span
                v-if="section === 13.1 || section === 13.2"
                class="text-body-1 font-weight-bold"
                :class="$_presentationStatusColor(item.flag_status)"
              >
                {{ $_presentationStatus(item.flag_status) }}
              </span>

              <!--Emails-->
              <span
                v-else-if="section === 24"
                class="text-body-1 font-weight-bold"
                :class="item.flag_status == false ? 'error--text': 'success--text'"
              >
                {{ $_emailStatus(item.flag_status) }}
              </span>

              <!--Users Settings-->
              <span
                v-else-if="section === 5 || section === 5.3"
                class="text-body-1 font-weight-bold"
                :class="item.flag_status == false ? 'error--text': 'success--text'"
              >
                {{ $_access(item.flag_status) }}
              </span>

              <!--Not Project Selector-->
              <span
                v-else-if="section !== 1.2 && section !== 4.1 && section !== 10.2 && section !== 11.2 && section !== 12.2 && section !== 14.2 && section !== 15.2 && section !== 29"
                class="text-body-1 font-weight-bold"
                :class="item.flag_status == false ? 'error--text': 'success--text'"
              >
                {{ $_active(item.flag_status) }}
              </span>
            </v-list-item-action-text>
            
            <!--Action Menu-->
            <v-menu
              v-if="actions.menu === 1"
              open-on-click
              offset-y
              left
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  large
                  v-on="on"
                >
                  <v-avatar
                    size="32px"
                    tile
                  >
                    <v-icon
                      color="primary"
                    >
                      fal fa-cog
                    </v-icon>
                  </v-avatar>
                </v-btn>
              </template>

              <v-list>
                <!--Action 1: View Detail-->
                <v-list-item
                  v-if="actions.view === 1"
                  @click.stop="openDetail(item.id)"
                >
                  <v-list-item-title class="clickable primary--text">
                    <v-icon
                      color="primary"
                      left
                    >
                      fal fa-eye
                    </v-icon> View {{ buttonTitle }}
                  </v-list-item-title>
                  <v-divider />
                </v-list-item>

                
                
                
                <!--Action 2: Edit Record-->
                <v-list-item
                  v-if="actions.edit === 1 && section === 26 && item.flag_status !== 1 && usersStore.authUser.flag_admin === 1 || 
                    actions.edit === 1 && section === 26 && item.flag_status === 1 || 
                    actions.edit === 1 && section === 26.12 && item.flag_status !== 1 && usersStore.authUser.flag_admin === 1 || 
                    actions.edit === 1 && section === 26.12 && item.flag_status === 1 ||

                    actions.edit === 1 && section === 26.13 && usersStore.authUser.flag_admin === 1 || 
                    actions.edit === 1 && section === 26.13 && item.flag_status === 1 && usersStore.authUserType !== 4 && usersStore.authUserType !== 7 ||

                    actions.edit === 1 && section === 26.14 && usersStore.authUser.flag_admin === 1 || 
                    actions.edit === 1 && section === 26.14 && item.flag_status === 1 && usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
                  @click.stop="openEntry(item.id)"
                >
                  <v-list-item-title class="clickable primary--text">
                    <v-icon
                      color="primary"
                      left
                    >
                      fal fa-pencil-alt
                    </v-icon> Edit {{ buttonTitle }}
                  </v-list-item-title>
                </v-list-item>

                <!--Action 2: Edit Record-->
                <v-list-item
                  v-if="actions.edit === 1 && section !== 26 && section !== 26.12 && section !== 26.13 && section !== 26.14 && usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
                  @click.stop="openEntry(item.id)"
                >
                  <v-list-item-title class="clickable primary--text">
                    <v-icon
                      color="primary"
                      left
                    >
                      fal fa-pencil-alt
                    </v-icon> Edit {{ buttonTitle }}
                  </v-list-item-title>
                </v-list-item>

                
                
                
                <!--Action 2: Edit Profile-->
                <v-list-item
                  v-if="actions.edit === 1 && section === 26 && item.flag_user_type <= 3 && item.flag_status === 1 ||
                    actions.edit === 1 && section === 26 && item.flag_user_type <= 3 && item.flag_status !== 1 && usersStore.authUser.flag_admin === 1 ||
                    actions.edit === 1 && section === 26.12 && item.flag_user_type <= 3 && item.flag_status === 1 ||
                    actions.edit === 1 && section === 26.12 && item.flag_user_type <= 3 && item.flag_status !== 1 && usersStore.authUser.flag_admin === 1 ||

                    actions.edit === 1 && section === 26.13 && item.flag_user_type <= 3 && usersStore.authUser.flag_admin === 1 ||

                    actions.edit === 1 && section === 26.13 && item.flag_user_type <= 3 && item.flag_status === 1 && usersStore.authUserType !== 4 && usersStore.authUserType !== 7 ||

                    actions.edit === 1 && section === 26.14 && item.flag_user_type <= 3 && usersStore.authUser.flag_admin === 1 ||

                    actions.edit === 1 && section === 26.14 && item.flag_user_type <= 3 && item.flag_status === 1 && usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
                  @click.stop="openProfile(item.id)"
                >
                  <v-list-item-title class="clickable primary--text">
                    <v-icon
                      color="primary"
                      left
                    >
                      fal fa-pencil-alt
                    </v-icon> Edit Profile
                  </v-list-item-title>
                </v-list-item>

                <!--Action 2: View Profile-->
                <v-list-item
                  v-if="section === 26 && item.flag_user_type <= 3 && item.flag_status !== 1 && usersStore.authUser.flag_admin !== 1||
                    section === 26.12 && item.flag_user_type <= 3 && item.flag_status !== 1 && usersStore.authUser.flag_admin !== 1 ||


                    section === 26.13 && item.flag_user_type <= 3 && item.flag_status !== 1 && usersStore.authUser.flag_admin !== 1 ||
                    section === 26.13 && item.flag_user_type <= 3 && item.flag_status === 1 && usersStore.authUserType === 4 ||
                    section === 26.13 && item.flag_user_type <= 3 && item.flag_status === 1 && usersStore.authUserType === 7 ||
                    section === 26.14 && item.flag_user_type <= 3 && item.flag_status !== 1 && usersStore.authUser.flag_admin !== 1 ||
                    section === 26.14 && item.flag_user_type <= 3 && item.flag_status === 1 && usersStore.authUserType === 4 ||
                    section === 26.14 && item.flag_user_type <= 3 && item.flag_status === 1 && usersStore.authUserType === 7"
                  @click.stop="openProfile(item.id)"
                >
                  <v-list-item-title class="clickable primary--text">
                    <v-icon
                      color="primary"
                      left
                    >
                      fal fa-eye
                    </v-icon> View Profile
                  </v-list-item-title>
                </v-list-item>




                <!--Action 3: Change Password-->
                <v-list-item
                  v-if="actions.password === 1 && usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
                  @click.stop="openChangePassword(item.id)"
                >
                  <v-list-item-title class="clickable primary--text">
                    <v-icon
                      color="primary"
                      left
                    >
                      fal fa-key
                    </v-icon> Change Password
                  </v-list-item-title>
                </v-list-item>

                <!--Action 4: Invite-->
                <v-list-item
                  v-if="actions.invite === 1 && usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
                  @click.stop="invite(item.id)"
                >
                  <v-list-item-title class="clickable primary--text">
                    <v-icon
                      color="primary"
                      left
                    >
                      fal fa-envelope-open-text
                    </v-icon> Invite
                  </v-list-item-title>
                </v-list-item>

                <!--Action 5: Email-->
                <v-list-item
                  v-if="actions.email === 1 && usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
                  @click.stop="openEmail(item.id)"
                >
                  <v-list-item-title class="clickable primary--text">
                    <v-icon
                      color="primary"
                      left
                    >
                      fal fa-at
                    </v-icon> Email
                  </v-list-item-title>
                </v-list-item>

                <!--Action 6: Delete-->
                <v-list-item
                  v-if="actions.delete === 1 && usersStore.authUser.flag_admin === 1 || actions.delete === 1 && usersStore.authUser.id === item.id_user || actions.delete === 1 && usersStore.authUserType === 1 || actions.delete === 1 && usersStore.authUserType === 5 || actions.delete === 1 && usersStore.authUserType === 6"
                  @click.stop="deleteEntry(item.id)"
                >
                  <v-list-item-title class="clickable primary--text">
                    <v-icon
                      color="primary"
                      left
                    >
                      fal fa-trash-alt
                    </v-icon> Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!--Single Action: Edit Record-->
            <v-tooltip
              v-if="actions.menu !== 1 && actions.edit === 1"
              left
              color="primary"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="openEntry(item.id)"
                >
                  <v-icon
                    v-if="usersStore.authUserType === 4 || usersStore.authUserType === 7 || usersStore.authUserType ===3 && section === 11.1 || usersStore.authUserType ===3 && section === 11.2"
                    color="primary"
                  >
                    fal fa-eye
                  </v-icon>

                  <v-icon
                    v-else
                    color="primary"
                  >
                    fal fa-pencil-alt
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <!--Single Action: Select-->
            <v-btn
              v-if="actions.menu !== 1 && actions.view > 1"
              rounded
              color="primary"
              @click.stop="openDetail(index)"
            >
              {{ $_getUserType(item.pivot.flag_user_type) }}
              <v-icon 
                right
                small
              >
                fal fa-arrow-right
              </v-icon>
            </v-btn>

            <!--Single Action: Delete - When User Created Record OR Principal Investigator OR Coordinator OR NSF EPSCoR-->
            <v-tooltip
              v-if="actions.menu !== 1 && actions.delete === 1 && usersStore.authUser.id === item.id_user || 
                actions.menu !== 1 && actions.delete === 1 && usersStore.authUserType === 1 ||
                actions.menu !== 1 && actions.delete === 1 && usersStore.authUserType === 5 ||
                actions.menu !== 1 && actions.delete === 1 && usersStore.authUserType === 6"
              left
              color="red lighten-1"
            >
              <template #activator="{ on }">
                <v-btn
                  class="mt-4"
                  icon
                  v-on="on"
                  @click="deleteEntry(item.id)"
                >
                  <v-icon
                    color="red lighten-1"
                  >
                    fal fa-trash-alt
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>

        <v-divider v-if="index + 1 < listData.length" />
      </template>
    </v-list>

    <v-list
      v-else
      class="py-2"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="error--text text-subtitle-1 text-xs-center">
            No {{ title }} Exist
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>      
</template>
    
<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TextDisplay from '@/components/TextDisplay.vue';

export default {
  name: 'ListBasic',
  components: {
    TextDisplay
  },
  mixins: [mixin],
  props: {
    show: Boolean,
    listData: Array,
    title: String,
    section: Number,
    buttonTitle:String,
    actions:Object,
  },
  data() {
    return {
      filterMenu: false,
    };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
    }),
  },
  methods: {
    openEntry(id) {
      this.filterMenu = false;
      this.$emit('open-entry',id)
    },
    deleteEntry(id) {
      this.filterMenu = false;
      this.$emit('delete-entry', id);
    },
    openDetail(id) {
      this.filterMenu = false;
      this.$emit('open-detail', id)
    },
    openChangePassword(id) {
      this.filterMenu = false;
      this.$emit('open-change-password', id)
    },
    openEmail(id) {
      this.filterMenu = false;
      this.$emit('open-email', id)
    },
    invite(id) {
      this.filterMenu = false;
      this.$emit('invite', id)
    },
    openProfile(id) {
      this.filterMenu = false;
      this.$emit('open-profile',id)
    },
  }

}
</script>

<style>
#bubble-label-success{
  background-color: #81c784;
  color: #ffffff;
  border: none;
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 16px;
}
#bubble-label-normal {
  border-style: solid;
  border-width: 1px;
  padding: 4px 10px;
  margin: 0px 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 16px;
}
</style>